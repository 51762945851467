import i18n from 'i18next';
import Backend from 'i18next-http-backend';

i18n.use(Backend).init({
    lng: window.navigator.language.split('-')[0], // Default language
    fallbackLng: 'en', // Fallback language if the requested language is not available
    debug: true, // Set to true to enable debug messages in the console
    backend: {
        loadPath: '/locales/{{lng}}.json' // URL pattern to load language files from the backend
    },
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
