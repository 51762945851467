import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import { lazy, Suspense } from 'react';
import { Spinner } from '@7shifts/sous-chef';

const TagsPage = lazy(() => import('./pages/TagsPage'));

export const ROUTES = [
    {
        path: '/tags',
        element: (
            <Suspense fallback={<Spinner block />}>
                <TagsPage />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />
    }
];
