import {
    Button,
    DateRangeField,
    Form,
    FormFooter,
    FormSection,
    Page,
    SelectOption,
    toast
} from '@7shifts/sous-chef';
import { getLeadsPerDayReport } from 'api/reportsApi';
import { useFormik } from 'formik';
import { __ } from 'i18n/translate';

import UserSelectField from 'sharedComponents/UserSelectField';
import { downloadCSV } from 'utils/csv';
import { dateYearMonthDay } from 'utils/date';

type FormData = {
    date: { start: Date; end: Date };
    user: SelectOption<number> | null;
};

const LeedsPerDayPage = () => {
    const formik = useFormik<FormData>({
        initialValues: {
            date: {
                start: new Date(),
                end: new Date()
            },
            user: null
        },
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            getLeadsPerDayReport(
                dateYearMonthDay(values.date.start),
                dateYearMonthDay(values.date.end),
                values.user?.value || null
            )
                .then((data) => {
                    downloadCSV(data, 'leads-per-day.csv');
                })
                .catch(() => {
                    toast('Something went wrong', 'danger');
                })
                .finally(() => formik.setSubmitting(false));
        }
    });
    return (
        <Page
            breadcrumbs={__('main.reports')}
            title={__('reportsPage.reportLeadsUserPerDay')}
            subtitle={__('reportsPage.reportLeadsUserPerDayDescription')}
        >
            <Form formik={formik}>
                <FormSection as="card">
                    <DateRangeField
                        name="date"
                        label="Período"
                        format="dd/MM/yyyy"
                    />
                    <UserSelectField
                        name="user"
                        placeholder={__('dashboardPage.allUsers')}
                        label="Usuário"
                        onChange={(userOption) =>
                            formik.setFieldValue('user', userOption)
                        }
                    />
                </FormSection>
                <FormFooter
                    actions={{
                        primary: (
                            <Button type="submit" loading={formik.isSubmitting}>
                                {__('main.downloadCSV')}
                            </Button>
                        )
                    }}
                />
            </Form>
        </Page>
    );
};

export default LeedsPerDayPage;
