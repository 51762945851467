import { ReactNode, useState } from 'react';
import { format } from 'date-fns/esm';
import { __ } from 'i18n/translate';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { socketConnection } from 'services/socket';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore this does not have types :/
import useSound from 'use-sound';
import alertSound from '../../assets/sound.mp3';
import { WebsocketMessage } from 'types';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { NotificationContext } from './NotificationContext';

type Props = {
    userId: number;
    companyId: number;
    children: ReactNode;
};

const NotificationsProvider = ({ userId, companyId, children }: Props) => {
    const navigate = useNavigate();
    const [play] = useSound(alertSound);
    const [userSoundEnabledFromStorage] = useLocalStorage(
        'userSoundEnabled',
        true
    );
    const [userPopupEnabledFromStorage] = useLocalStorage(
        'userPopupEnabled',
        true
    );
    const [userNotificationSoundEnabled, setUserNotificationSoundEnabled] =
        useState(userSoundEnabledFromStorage);
    const [userNotificationEnabled, setUserNotificationEnabled] = useState(
        userPopupEnabledFromStorage
    );

    // console.log('userNotificationEnabled', userNotificationEnabled);
    // console.log('userNotificationSoundEnabled', userNotificationSoundEnabled);
    // console.log('userSoundEnabledFromStorage', userSoundEnabledFromStorage);
    // console.log('userPopupEnabledFromStorage', userPopupEnabledFromStorage);

    useEffect(() => {
        if (!('Notification' in window)) {
            console.log("This browser doesn't support notifications");
        } else {
            Notification.requestPermission();
        }
    }, []);

    useEffect(() => {
        if (!userNotificationEnabled) {
            return;
        }
        const socket = socketConnection({ companyId });

        socket.on('connect', () => socket.emit('joinNotification'));

        const handleMessage = (data: WebsocketMessage) => {
            console.log('data', data);
            if (
                data.action === 'create' &&
                !data.message.read &&
                !data.ticket.chatbot &&
                (data.ticket.userId === userId || !data.ticket.userId)
            ) {
                const { message, contact, ticket } = data;

                const options = {
                    body: `${message.body} - ${format(new Date(), 'HH:mm')}`,
                    icon:
                        !contact.profilePicUrl ||
                        contact.profilePicUrl.indexOf('nopicture') > -1
                            ? 'https://selene-producao.s3.sa-east-1.amazonaws.com/logo.png'
                            : contact.profilePicUrl
                };

                console.log('WILL send notification', options);

                const notification = new Notification(
                    __('tickets.messageFrom', { name: contact.name }),
                    options
                );
                notification.addEventListener('click', () => {
                    console.log('clicked notification', `/tickets?ticket=${ticket.uuid}`);
                    window.focus();
                    navigate(`/tickets?ticket=${ticket.uuid}`);
                });

                if (userNotificationSoundEnabled) {
                    play();
                }
            }
        };

        socket.on(`company-${companyId}-appMessage`, handleMessage);

        return () => {
            socket.off(`company-${companyId}-appMessage`, handleMessage);
            socket.disconnect();
        };
    }, [
        userId,
        companyId,
        userNotificationEnabled,
        userNotificationSoundEnabled,
        navigate,
        play
    ]);

    return (
        <NotificationContext.Provider
            value={{
                userNotificationEnabled,
                userNotificationSoundEnabled,
                setUserNotificationEnabled,
                setUserNotificationSoundEnabled
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationsProvider;
