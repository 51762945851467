import { SelectOption } from '@7shifts/sous-chef';
import { __ } from 'i18n';

export const getHeaderTypeOptions = (): SelectOption<string>[] => [
    {
        label: __('messageTemplateForm.noneContentForm'),
        value: ''
    },
    {
        label: __('messageTemplateForm.textContentForm'),
        value: 'text'
    },
    {
        label: __('messageTemplateForm.imageContentForm'),
        value: 'image'
    },
    {
        label: __('messageTemplateForm.videoContentForm'),
        value: 'video'
    },
    {
        label: __('messageTemplateForm.documentContentForm'),
        value: 'document'
    }
];

export const getModelTypeOptions = (): SelectOption<string>[] => [
    {
        label: __('messageTemplateForm.modelTypeUtility'),
        value: 'UTILITY'
    },
    {
        label: __('messageTemplateForm.modelTypeMarketing'),
        value: 'MARKETING'
    }
];
