import { ReactNode, createContext, useContext } from 'react';
import useAuth from 'hooks/useAuth';
import { User } from 'types';
import { ContextType } from './types';

const AuthContext = createContext<ContextType>({} as ContextType);

type Props = {
    children: ReactNode;
};
const AuthProvider = ({ children }: Props) => {
    const { loading, user, isAuth, handleLogin, handleLogout } = useAuth();

    return (
        <AuthContext.Provider
            value={{
                loading,
                user: user as User,
                isAuth,
                handleLogin,
                handleLogout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuthContext = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('Auth context not in place!');
    }

    return context;
};

export { AuthContext, AuthProvider, useAuthContext };
