import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import { lazy, Suspense } from 'react';
import { Spinner } from '@7shifts/sous-chef';

const QueuesPage = lazy(() => import('./pages/QueuesPage'));
const EditQueueOptionsPage = lazy(() => import('./pages/EditQueueOptionsPage'));

export const ROUTES = [
    {
        path: '/queues',
        element: (
            <Suspense fallback={<Spinner block />}>
                <QueuesPage />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />
    },
    {
        path: '/queues/:id/options',
        element: (
            <Suspense fallback={<Spinner block />}>
                <EditQueueOptionsPage />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />
    }
];
