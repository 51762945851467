import { useState } from 'react';

export function useLocalStorage<T>(
    key: string,
    initialValue: T
): [T, (value: T) => void] {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(
                `Some error happening trying to get ${key} from the local storage!`
            );
            return initialValue;
        }
    });

    const setValue = (value: T) => {
        try {
            const valueToStore = value;

            setStoredValue(valueToStore);

            localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error(
                `Some error happening trying to set ${key} to the local storage!`
            );
        }
    };

    return [storedValue, setValue];
}
