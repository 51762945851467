import { Page } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import InvoicesTable from 'modules/Invoices/components/InvoicesTable';

const InvoicesPage = () => {
    return (
        <Page title={__('main.invoices')}>
            <InvoicesTable />
        </Page>
    );
};

export default InvoicesPage;
