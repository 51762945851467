import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router.tsx';
import './main.scss';
import 'app-layout-frame/dist/index.css';
import { AuthProvider } from './contexts/AuthContext.tsx';
import TranslationProvider from './contexts/TranslationContext.tsx';
import { Modal } from '@7shifts/sous-chef';
import '@7shifts/sous-chef/dist/index.css';
import SousChefProvider from 'sharedComponents/SousChefProvider';
import { initializeSentry } from 'utils/logging.ts';

initializeSentry();

Modal.setAppElement('#root');

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <TranslationProvider>
            <SousChefProvider>
                <AuthProvider>
                    <Router />
                </AuthProvider>
            </SousChefProvider>
        </TranslationProvider>
    </React.StrictMode>
);
