import { differenceInDays, format, isToday, isYesterday } from 'date-fns/esm';
import { __ } from 'i18n';

const DAYS = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
];

export const formmatRelative = (
    date: Date,
    timeFormat: '24h' | '12h' = '24h'
) => {
    if (isToday(date)) {
        if (timeFormat === '24h') {
            return format(date, 'HH:mm');
        }
        return format(date, 'K bb');
    }
    if (isYesterday(date)) {
        return __('date.yesterday');
    }

    const difference = differenceInDays(new Date(), date);
    if (difference < 7) {
        return __(`date.${DAYS[date.getDay()]}`);
    }
    return format(date, 'dd/MM/yyyy');
};

export const parseAMPMTo24hrs = (time: string): string | null | undefined => {
    const matches = time.match(/^(\d+):(\d+)\s?(AM|PM)$/);

    if (matches) {
        let hours = +matches[1];
        let minutes = +matches[2];
        const isPM = matches[3] === 'PM';

        if (isPM && hours !== 12) {
            hours += 12;
        } else if (!isPM && hours === 12) {
            hours = 0;
        }

        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
        if (hours < 10) hours = `0${hours}`;
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
        if (minutes < 10) minutes = `0${minutes}`;
        return `${hours}:${minutes}`;
    } else {
        return null;
    }
};

export function formatTimeAMPM(time: string, withSpace = true): string {
    const [hour, minutes] = time.split(':');

    const datetimeInClientTimezone = new Date(
        new Date().getFullYear(), // get current year because leap seconds https://en.wikipedia.org/wiki/Leap_second
        0,
        0,
        Number(hour),
        Number(minutes),
        0,
        0
    );
    const timeFormat = withSpace ? 'h:mm aa' : 'h:mmaa';
    return format(datetimeInClientTimezone, timeFormat);
}

export const dateYearMonthDay = (date = new Date()) =>
    format(date, 'yyyy-MM-dd');
