import { Spinner } from '@7shifts/sous-chef';
import LeedsInAutomationPage from 'modules/Reports/pages/LeedsInAutomationPage';
import LeedsPerDayPage from 'modules/Reports/pages/LeedsPerDayPage';
import { lazy, Suspense } from 'react';
import ErrorBoundary from 'sharedComponents/ErrorBoundary';

const ReportsPage = lazy(() => import('./pages/ReportsPage'));

export const ROUTES = [
    {
        path: '/reports',
        element: (
            <Suspense fallback={<Spinner block />}>
                <ReportsPage />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />
    },
    {
        path: '/reports/leads-per-day',
        element: (
            <Suspense fallback={<Spinner block />}>
                <LeedsPerDayPage />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />
    },
    {
        path: '/reports/leads-in-automation',
        element: (
            <Suspense fallback={<Spinner block />}>
                <LeedsInAutomationPage />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />
    }
];
