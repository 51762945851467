import { DataTable, DataTableColumn } from '@7shifts/sous-chef';
import { getInvoices } from 'api/invoicesApi';
import { __ } from 'i18n/translate';
import InvoicesTableRow from 'modules/Invoices/components/InvoicesTableRow';
import { useEffect, useState } from 'react';
import { Invoice } from 'types';

const InvoicesTable = () => {
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getInvoices()
            .then(setInvoices)
            .finally(() => setIsLoading(false));
    }, []);

    const columns: DataTableColumn[] = [
        {
            name: 'id',
            label: __('invoices.id'),
            size: 0.4
        },
        {
            name: 'detail',
            label: __('invoices.plan')
        },
        {
            name: 'value',
            label: __('invoices.amount')
        },
        {
            name: 'dueDate',
            label: __('invoices.dueDate')
        },
        {
            name: 'status',
            label: __('invoices.status')
        }
    ];
    return (
        <DataTable
            items={invoices}
            columns={columns}
            isLoading={isLoading}
            itemComponent={InvoicesTableRow}
            showActionMenu
        />
    );
};

export default InvoicesTable;
