import MarkdownWrapper from 'modules/Tickets/components/MarkdownWrapper';
import './styles.scss';
import { IconDownload, IconImage, IconVideo } from '@7shifts/sous-chef';

type Props = {
    showHeader: boolean;
    showFooter: boolean;
    showButtons: boolean;
    headerType: string;
    headerContent: string;
    bodyText: string;
    footerText: string;
    buttons: string[];
};

const TemplatePreview = ({
    showHeader,
    showFooter,
    showButtons,
    headerType,
    headerContent,
    bodyText,
    footerText,
    buttons
}: Props) => {
    return (
        <>
            <div className="template-preview">
                <div className="template-system-message" key={`timestamp-1`}>
                    {showHeader && (
                        <>
                            {headerType === 'text' && (
                                <div className="template-system-message__header template-system-message__header__text">
                                    <MarkdownWrapper>
                                        {headerContent}
                                    </MarkdownWrapper>
                                </div>
                            )}
                            {headerType === 'document' && (
                                <div className="template-system-message__header template-system-message__header__document">
                                    <IconDownload size="large" />
                                </div>
                            )}
                            {headerType === 'image' && (
                                <div className="template-system-message__header template-system-message__header__image">
                                    {!headerContent ? (
                                        <IconImage
                                            size="large"
                                            className="template-system-message__header__image__img"
                                        />
                                    ) : (
                                        <img
                                            src={headerContent}
                                            alt="image"
                                            className="template-system-message__header__image__img"
                                        />
                                    )}
                                </div>
                            )}
                            {headerType === 'video' && (
                                <div className="template-system-message__header">
                                    {!headerContent ? (
                                        <IconVideo
                                            size="large"
                                            className="template-system-message__header__icon-video"
                                        />
                                    ) : (
                                        <video
                                            controls
                                            className="template-system-message__header__video"
                                            src={headerContent}
                                        />
                                    )}
                                </div>
                            )}
                        </>
                    )}

                    <div className="template-system-message__text">
                        <MarkdownWrapper>{bodyText}</MarkdownWrapper>
                    </div>
                    {showFooter && (
                        <span className="template-system-message__timestamp">
                            {footerText}
                        </span>
                    )}
                </div>
                {showButtons && (
                    <div className="template-preview__buttons">
                        {buttons.map((text, index) => (
                            <div key={index}>{text}</div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default TemplatePreview;
