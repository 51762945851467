import { Button, EmptyState, Inline, Page } from '@7shifts/sous-chef';
import errorStateIllustration from '../../assets/error-state.svg';
import { useRouteError } from 'react-router-dom';
import * as logging from 'utils/logging';
import { __ } from 'i18n';

const ErrorBoundary = () => {
    const error = useRouteError() as Error;

    logging.error(error.message, error);

    return (
        <Page>
            <Inline mt={120}>
                <EmptyState
                    actions={{
                        primary: (
                            <Button
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                {__('errorBoundary.reload')}
                            </Button>
                        )
                    }}
                    mediaUrl={errorStateIllustration}
                    title={__('errorBoundary.title')}
                >
                    {__('errorBoundary.description')}
                </EmptyState>
            </Inline>
        </Page>
    );
};

export default ErrorBoundary;
