/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectOption } from '@7shifts/sous-chef';
import { uploadMedia } from 'api/metaTemplatesApi';
import { __ } from 'i18n/translate';

type Request = {
    headerType: SelectOption<string>;
    headerContent?: string;
    headerContentFile?: File;
    name: string;
    type: SelectOption<string>;
    bodyText: string;
    footerText: string;
    buttons: string[];
};

const allowedVideos: string[] = ['video/3gp', 'video/mp4'];
const allowedVideosExtensions: string[] = ['.3gp', '.mp4'];
const allowedImages: string[] = ['image/jpeg', 'image/png', 'image/webp'];
const allowedImagesExtensions: string[] = ['.jpeg', '.png', '.webp'];
const allowedDocuments: string[] = [
    'text/plain',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/pdf'
];
const allowedDocumentExtensions: string[] = [
    '.txt',
    '.doc',
    '.docx',
    '.ppt',
    '.pptx',
    '.xls',
    '.xlsx',
    '.pdf'
];
export function checkMediaType(file: File, headerType: string) {
    if (headerType === 'image') {
        if (allowedImages.indexOf(file.type) === -1) {
            throw __('main.formatError', {
                formats: allowedImagesExtensions.join(', ')
            });
        }
        if (file.size > 5 * 1024 * 1024) {
            throw __('main.sizeError', {
                size: 5
            });
        }
    } else if (headerType === 'video') {
        if (allowedVideos.indexOf(file.type) === -1) {
            throw __('main.formatError', {
                formats: allowedVideosExtensions.join(', ')
            });
        }
        if (file.size > 16 * 1024 * 1024) {
            throw __('main.sizeError', {
                size: 16
            });
        }
    } else if (headerType === 'document') {
        if (allowedDocuments.indexOf(file.type) === -1) {
            throw __('main.formatError', {
                formats: allowedDocumentExtensions.join(', ')
            });
        }
        if (file.size > 100 * 1024 * 1024) {
            throw __('main.sizeError', {
                size: 100
            });
        }
    }
}

export async function generateMetaPayload(payload: Request) {
    const requestObject: any = {
        name: payload.name,
        category: payload.type.value,
        language: 'pt_BR',
        components: [
            {
                type: 'BODY',
                text: payload.bodyText
            }
        ]
    };
    switch (payload.headerType.value) {
        case 'text':
            requestObject.components.push({
                type: 'HEADER',
                format: 'TEXT',
                text: payload.headerContent
            });
            break;
        case 'image':
            checkMediaType(
                payload.headerContentFile!,
                payload.headerType.value
            );
            requestObject.components.push({
                type: 'HEADER',
                format: 'IMAGE',
                example: {
                    header_handle: [
                        await uploadMedia(payload.headerContentFile!)
                    ]
                }
            });
            break;
        case 'video':
            checkMediaType(
                payload.headerContentFile!,
                payload.headerType.value
            );
            requestObject.components.push({
                type: 'HEADER',
                format: 'VIDEO',
                example: {
                    header_handle: [
                        await uploadMedia(payload.headerContentFile!)
                    ]
                }
            });
            break;
        case 'document':
            checkMediaType(
                payload.headerContentFile!,
                payload.headerType.value
            );
            requestObject.components.push({
                type: 'HEADER',
                format: 'DOCUMENT',
                example: {
                    header_handle: [
                        await uploadMedia(payload.headerContentFile!)
                    ]
                }
            });
            break;
    }
    if (payload.buttons && payload.buttons.length > 0) {
        requestObject.components.push({
            type: 'BUTTONS',
            buttons: payload.buttons.map((text) => {
                return { type: 'QUICK_REPLY', text };
            })
        });
    }
    console.log(payload);
    console.log(requestObject);
    return requestObject;
}
