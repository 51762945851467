import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/types';

export const initializeSentry = () => {
    Sentry.init({
        dsn: 'https://222cd66ea110a1fdcd938fcdcef5a29d@o4506062462320640.ingest.us.sentry.io/4507657637068800',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false
            })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'apphom.selenebot.com.br/',
            'app.selenebot.com.br/',
            'novo.selenebot.com.br/',
            'apphomv2.selenebot.com.br/'
        ],

        // This will send Stack Trace even on simple log (those that are logged without an error)
        attachStacktrace: true,
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
};

const logToSentry = (
    level: SeverityLevel,
    message: string,
    metadata: unknown = null
) => {
    /*
    if (!shouldSendLogsToSentry()) {
        console.warn(`[Local logging]: ${message}`, metadata);
        return;
    }
        */

    Sentry.withScope(function (scope) {
        scope.setLevel(level);
        if (metadata instanceof Error) {
            Sentry.captureException(metadata, {
                extra: { message: message }
            });
        } else {
            Sentry.captureMessage(message, {
                extra: { metadata }
            });
        }
    });
};

export const info = (message: string, metadata: unknown = null) => {
    logToSentry('debug', message, metadata);
};

export const warn = (message: string, metadata: unknown = null) => {
    logToSentry('warning', message, metadata);
};

export const error = (message: string, metadata: unknown = null) => {
    logToSentry('error', message, metadata);

    // eslint-disable-next-line no-console
    console.error(message);
};
export const critical = (message: string, metadata: unknown = null) => {
    logToSentry('fatal', message, metadata);
};
