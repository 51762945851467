import { Button, Form, FormFooter, Page, toast } from '@7shifts/sous-chef';
import { getLeedsInAutomationReport } from 'api/reportsApi';
import { useFormik } from 'formik';
import { __ } from 'i18n/translate';
import { downloadCSV } from 'utils/csv';

const LeedsInAutomationPage = () => {
    const formik = useFormik({
        initialValues: {},
        onSubmit: (_values, { setSubmitting }) => {
            setSubmitting(true);
            getLeedsInAutomationReport()
                .then((data) => {
                    downloadCSV(data, 'leads-in-automation.csv');
                })
                .catch(() => {
                    toast('Something went wrong', 'danger');
                })
                .finally(() => formik.setSubmitting(false));
        }
    });
    return (
        <Page
            breadcrumbs={__('main.reports')}
            title={__('reportsPage.reportLeadsInAutomation')}
            subtitle={__('reportsPage.reportLeadsInAutomationDescription')}
        >
            <Form formik={formik}>
                <FormFooter
                    actions={{
                        primary: (
                            <Button type="submit" loading={formik.isSubmitting}>
                                {__('main.downloadCSV')}
                            </Button>
                        )
                    }}
                />
            </Form>
        </Page>
    );
};

export default LeedsInAutomationPage;
