export const downloadCSV = (content: string, filename: string) => {
    const encodedUri: string = encodeURIComponent(content);
    const link: HTMLAnchorElement = document.createElement('a');
    link.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,%EF%BB%BF' + encodedUri
    );
    const doc = document.body;

    if (doc === null) {
        return;
    }

    if (filename) {
        link.setAttribute('download', filename);
    }

    doc.appendChild(link);
    link.click();
    doc.removeChild(link);
};
