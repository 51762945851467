import { toast } from '@7shifts/sous-chef';
import { AxiosError } from 'axios';
import { __ } from 'i18n';

export const handleError = (err: AxiosError) => {
    const message = (err as AxiosError<{ error: string }>).response?.data.error;

    toast(
        message ? __(`apiErrors.${message}`) : __('default.genericError'),
        'danger'
    );
};
