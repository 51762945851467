import { createContext, useContext } from 'react';

export type ContextType = {
    userNotificationEnabled: boolean;
    userNotificationSoundEnabled: boolean;
    setUserNotificationSoundEnabled: (v: boolean) => void;
    setUserNotificationEnabled: (v: boolean) => void;
};

const NotificationContext = createContext<ContextType>({} as ContextType);

const useNotificationContext = () => {
    const context = useContext(NotificationContext);

    if (!context) {
        throw new Error('Auth context not in place!');
    }

    return context;
};

export { NotificationContext, useNotificationContext };
