import { ReactNode, useState } from 'react';
import { Spinner } from '@7shifts/sous-chef';
import i18n from 'i18n/i18n';
import { I18nextProvider } from 'react-i18next';

type Props = {
    children: ReactNode;
};
const TranslationProvider = ({ children }: Props) => {
    const [isLoaded, setIsLoaded] = useState(false);

    i18n.on('loaded', () => {
        setIsLoaded(true);
    });
    return (
        <I18nextProvider i18n={i18n}>
            {isLoaded || i18n.isInitialized ? children : <Spinner block />}
        </I18nextProvider>
    );
};

export default TranslationProvider;
