import api from '../services/api';
import { User } from 'types';

export type GetUsersResponse = {
    users: User[];
    count: number;
    hasMore: boolean;
};

export const getUsers = (
    searchParam: string,
    pageNumber: number,
    departmentId?: number
): Promise<GetUsersResponse> => {
    return api
        .get('/users/', {
            params: { searchParam, pageNumber, queueId: departmentId }
        })
        .then(({ data }) => data);
};

export const getUser = (id: number): Promise<User> => {
    return api.get('/users/' + id).then(({ data }) => data);
};

type UserData = {
    name: string;
    email: string;
    password: string;
    profile: string;
    isActive: boolean;
    isAttendant: boolean;
    onVacation: boolean;
    queueIds: number[];
};
export const addUser = (newUser: UserData): Promise<User> => {
    return api.post('/users', newUser).then(({ data }) => data);
};

type EditUserData = {
    companyId: number;
} & UserData;
export const editUser = (
    userId: number,
    editingUser: EditUserData
): Promise<User> => {
    return api.put('/users/' + userId, editingUser).then(({ data }) => data);
};

export const deleteUser = (userId: number): Promise<void> => {
    return api.delete('/users/' + userId);
};

export const changeStatus = (
    userId: number,
    status: 'available' | 'away'
): Promise<void> => {
    return getUser(userId).then((user) => {
        return api.put('/users/updateStatus/' + userId, {
            ...user,
            statusAvailable: status === 'available' ? true : false
        });
    });
};
