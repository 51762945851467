import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import InvoicesPage from './pages/InvoicesPage';

export const ROUTES = [
    {
        path: '/invoices',
        element: <InvoicesPage />,
        errorElement: <ErrorBoundary />
    }
];
