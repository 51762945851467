import { Button, PersistentBanner } from '@7shifts/sous-chef';
import { useWhatsContext } from 'contexts/WhatsAppContext/WhatsAppContext';
import { __ } from 'i18n/translate';
import { useLocation, useNavigate } from 'react-router-dom';

const NoChannelPersistentBanner = () => {
    const { loading, whatsApps } = useWhatsContext();
    const navigate = useNavigate();

    const { pathname } = useLocation();

    if (loading || pathname === '/channels') {
        return null;
    }
    if (whatsApps.length > 0) {
        const nonConnectedChannel = whatsApps.find(
            (item) => item.status !== 'CONNECTED'
        );

        if (nonConnectedChannel) {
            return (
                <PersistentBanner
                    theme="info"
                    primaryButton={
                        <Button onClick={() => navigate('/channels')}>
                            {__('channelsPage.fixIt')}
                        </Button>
                    }
                >
                    {__('channelsPage.unconnectedChannelBannerMessage')}
                </PersistentBanner>
            );
        }

        return null;
    }

    return (
        <PersistentBanner
            theme="danger"
            primaryButton={
                <Button
                    onClick={() =>
                        navigate('/channels', {
                            state: {
                                add: true
                            }
                        })
                    }
                >
                    {__('channelsPage.addWhatsApp')}
                </Button>
            }
        >
            {__('channelsPage.noChannelBannerMessage')}
        </PersistentBanner>
    );
};

export default NoChannelPersistentBanner;
