/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup';
import { __ } from 'i18n';
import { SelectOption } from '@7shifts/sous-chef';

export const getSchema = () => {
    return Yup.object().shape({
        name: Yup.string()
            .max(512)
            .required(__('messageTemplateForm.nameRequired'))
            .matches(
                /^([a-z_]*)$/g,
                __('messageTemplateForm.templateNameFormat')
            ),
        type: Yup.object().required(__('messageTemplateForm.typeRequired')),
        headerContent: Yup.string().when('headerType', {
            is: (value: SelectOption<string>) => value.value === 'text',
            then: () =>
                Yup.string()
                    .min(5, __('messageTemplateForm.headerTextMinimal'))
                    .required(__('messageTemplateForm.headerTextRequired'))
        }),
        bodyText: Yup.string()
            .min(5, __('messageTemplateForm.bodyTextMin'))
            .max(1024, __('messageTemplateForm.bodyTextMax'))
            .required(__('messageTemplateForm.bodyTextRequired')),
        footerText: Yup.string().test(
            'is-filled',
            __('messageTemplateForm.footerTextRequired'),
            (value) => {
                return !value || value.length > 5;
            }
        ),
        buttons: Yup.array()
            .of(
                Yup.string().required(__('messageTemplateForm.buttonsRequired'))
            )
            .max(3, __('messageTemplateForm.maxButtons'))
    });
};
