import {
    DataTableAction,
    DataTableCell,
    DataTableCustomComponent,
    DataTableRow,
    Pill,
    Stack,
    Text
} from '@7shifts/sous-chef';
import { __ } from 'i18n/translate';
import { Invoice } from 'types';
import { formmatRelative } from 'utils/date';

const InvoicesTableRow = ({ item }: DataTableCustomComponent<Invoice>) => {
    const actions: DataTableAction[] = [];

    if (item.status !== 'paid' && item.link) {
        actions.push({
            action: 'pay',
            label: __('invoices.payInvoice'),
            onAction: () => {
                if (!item.link) {
                    return;
                }
                window.open(item.link, '_blank');
            },
            showInKebab: false,
            buttonProps: {
                theme: 'primary'
            }
        });
    }
    return (
        <DataTableRow actions={actions}>
            <DataTableCell columnIndex={0}>{item.id}</DataTableCell>
            <DataTableCell columnIndex={1}>{item.detail}</DataTableCell>
            <DataTableCell columnIndex={2}>
                R${' '}
                {item.value.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}
            </DataTableCell>
            <DataTableCell columnIndex={3}>
                {formmatRelative(new Date(item.dueDate))}
            </DataTableCell>
            <DataTableCell columnIndex={4}>
                <Stack space={4}>
                    <Pill theme={item.status === 'paid' ? 'success' : 'danger'}>
                        {__(`invoices.${item.status}`)}
                    </Pill>
                    {item.paidDate && (
                        <Text as="caption">
                            {__('invoices.paidOn', {
                                date: formmatRelative(new Date(item.paidDate))
                            })}
                        </Text>
                    )}
                </Stack>
            </DataTableCell>
        </DataTableRow>
    );
};

export default InvoicesTableRow;
