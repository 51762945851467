import axios from 'axios';

const BASE_URL = import.meta.env.VITE_REACT_APP_BACKEND_URL;

const api = axios.create({
    baseURL: BASE_URL,
    withCredentials: true
});

export const openApi = axios.create({
    baseURL: BASE_URL
});

export default api;
