import { SousChefProvider as SousChefProviderOriginal } from '@7shifts/sous-chef';
import en from '@7shifts/sous-chef/dist/i18n/locales/en.json';
import es from '@7shifts/sous-chef/dist/i18n/locales/es.json';
import pt from './locales/pt.json';
import i18n from 'i18n/i18n';

type Props = {
    children: React.ReactNode;
};

const SousChefProvider = ({ children }: Props) => {
    return (
        <SousChefProviderOriginal i18n={getLocale()} country="BR">
            {children}
        </SousChefProviderOriginal>
    );
};

const getLocale = () => {
    switch (i18n.language) {
        case 'es':
            return es;
        case 'pt':
            return pt;
        default:
            return en;
    }
};

export default SousChefProvider;
