import { Spinner } from '@7shifts/sous-chef';
import { lazy, Suspense } from 'react';
import ErrorBoundary from 'sharedComponents/ErrorBoundary';

const QuickMessagesPage = lazy(() => import('./pages/QuickMessagesPage'));

export const ROUTES = [
    {
        path: '/quick-messages',
        element: (
            <Suspense fallback={<Spinner block />}>
                <QuickMessagesPage />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />
    }
];
