import { Page } from '@7shifts/sous-chef';
import MessageTemplateForm from '../components/MessageTemplateForm/MessageTemplateForm';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const MessageTemplatesNewPage = () => {
    const { id } = useParams();
    useEffect(() => {
        if (!id) {
            throw new Error('No queue ID present');
        }
    }, [id]);

    return (
        id && (
            <Page>
                <MessageTemplateForm whatsappId={Number(id)} />
            </Page>
        )
    );
};

export default MessageTemplatesNewPage;
