import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import { lazy, Suspense } from 'react';
import { Spinner } from '@7shifts/sous-chef';

const MyAccountPage = lazy(() => import('./pages/MyAccountPage'));

export const ROUTES = [
    {
        path: '/my-account',
        element: (
            <Suspense fallback={<Spinner block />}>
                <MyAccountPage />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />
    }
];
