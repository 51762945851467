import openSocket from 'socket.io-client';
import { isObject } from 'lodash';

export function socketConnection(params: object) {
    let userId = null;
    if (localStorage.getItem('userId')) {
        userId = localStorage.getItem('userId');
    }
    return openSocket(import.meta.env.VITE_REACT_APP_SOCKET_URL as string, {
        transports: ['websocket', 'polling'],
        //pingTimeout: 18000,
        //pingInterval: 18000,
        query: isObject(params) ? { ...params, userId } : { userId }
    });
}
