import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import { lazy, Suspense } from 'react';
import { Spinner } from '@7shifts/sous-chef';

const LoginPage = lazy(() => import('./pages/LoginPage'));
const SignupPage = lazy(() => import('./pages/SignupPage'));

export const ROUTES = [
    {
        path: '/login',
        element: (
            <Suspense fallback={<Spinner block />}>
                <LoginPage />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />
    },
    {
        path: '/signup',
        element: (
            <Suspense fallback={<Spinner block />}>
                <SignupPage />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />
    }
];
