import { useState, useEffect } from 'react';
import { has, isArray } from 'lodash';
import api from '../services/api';
import { socketConnection } from '../services/socket';
import { toast } from '@7shifts/sous-chef';
import { AxiosError } from 'axios';
import { __ } from 'i18n';
import { format, isFuture } from 'date-fns/esm';
import { User } from 'types';

const useAuth = () => {
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<User>();

    api.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
                setIsAuth(true);
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            if (error?.response?.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true;

                const refreshTokenRequest =
                    localStorage.getItem('refreshToken');
                if (refreshTokenRequest) {
                    api.defaults.headers['x-auth-token'] = refreshTokenRequest;
                }

                const { data } = await api.post('/auth/refresh_token');
                if (data) {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('refreshToken', data.refreshToken);
                    api.defaults.headers['x-auth-token'] = data.refreshToken;
                    api.defaults.headers.Authorization = `Bearer ${data.token}`;
                }
                return api(originalRequest);
            }
            if (error?.response?.status === 401) {
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('token');
                localStorage.removeItem('companyId');
                api.defaults.headers.Authorization = '';
                setIsAuth(false);
            }
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        const token = localStorage.getItem('token');
        (async () => {
            if (token) {
                try {
                    const refreshTokenRequest =
                        localStorage.getItem('refreshToken');

                    if (refreshTokenRequest) {
                        api.defaults.headers['x-auth-token'] =
                            refreshTokenRequest;
                    }
                    const { data } = await api.post('/auth/refresh_token');
                    localStorage.setItem('refreshToken', data.refreshToken);
                    api.defaults.headers['x-auth-token'] = data.refreshToken;
                    api.defaults.headers.Authorization = `Bearer ${data.token}`;
                    setIsAuth(true);
                    setUser(data.user);
                } catch (err: unknown) {
                    const message = (err as AxiosError<{ error: string }>)
                        .response?.data.error;

                    toast(
                        __(`apiErrors.${message}` || 'default.genericError'),
                        'danger'
                    );
                }
            }
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        const companyId = localStorage.getItem('companyId');
        const socket = socketConnection({ companyId });

        socket.on(`company-${companyId}-user`, (data) => {
            if (data.action === 'update' && user && data.user.id === user.id) {
                setUser(data.user);
            }
        });

        socket.on(`company-${companyId}-auth`, (data) => {
            if (user && data.user.id === +user.id) {
                toast('Sua conta foi acessada em outro computador.', 'danger');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.reload();
                }, 2000);
            }
        });

        return () => {
            socket.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const handleLogin = async (userData: {
        email: string;
        password: string;
    }): Promise<boolean> => {
        setLoading(true);

        try {
            const { data } = await api.post('/auth/login', userData);
            const {
                user: { companyId, id, company }
            } = data;

            if (has(company, 'settings') && isArray(company.settings)) {
                const setting = company.settings.find(
                    (s: { key: string }) => s.key === 'campaignsEnabled'
                );
                if (setting && setting.value === 'true') {
                    localStorage.removeItem('cshow'); //regra pra exibir campanhas
                }
            }

            const dueDate = new Date(data.user.company.dueDate);
            const dueDateFormmated = format(dueDate, 'dd/MM/yyyy');

            const isExpired = !isFuture(dueDate);

            if (isExpired) {
                toast(
                    __('loginPage.membershipExpired', {
                        expireDate: dueDateFormmated
                    }),
                    'danger'
                );
                setLoading(false);
                return false;
            }

            if (!localStorage.getItem('userSoundEnabled')) {
                localStorage.setItem('userSoundEnabled', 'true');
            }

            if (!localStorage.getItem('userPopupEnabled')) {
                localStorage.setItem('userPopupEnabled', 'true');
            }
            localStorage.setItem('token', data.token);
            localStorage.setItem('refreshToken', data.refreshToken);
            localStorage.setItem('companyId', companyId);
            localStorage.setItem('userId', id);
            localStorage.setItem('companyDueDate', data.user.company.dueDate);
            api.defaults.headers.Authorization = `Bearer ${data.token}`;
            api.defaults.headers['x-auth-token'] = data.refreshToken;
            setUser(data.user);
            setIsAuth(true);
            setLoading(false);
            return true;
        } catch (err: unknown) {
            const message = (err as AxiosError<{ error: string }>).response
                ?.data.error;

            toast(
                __(`apiErrors.${message}` || 'default.genericError'),
                'danger'
            );
            setLoading(false);
            return false;
        }
    };

    const handleLogout = async (): Promise<boolean> => {
        setLoading(true);

        try {
            await api.delete('/auth/logout');
            setIsAuth(false);
            setUser(undefined);
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('companyId');
            localStorage.removeItem('userId');
            localStorage.removeItem('cshow');
            api.defaults.headers.Authorization = '';
            setLoading(false);
            return true;
        } catch (err) {
            toast(err as string, 'danger');
            setLoading(false);
            return false;
        }
    };

    const getCurrentUserInfo = async () => {
        try {
            const { data } = await api.get('/auth/me');
            return data;
        } catch (err) {
            toast(err as string, 'danger');
        }
    };

    return {
        isAuth,
        user,
        loading,
        handleLogin,
        handleLogout,
        getCurrentUserInfo
    };
};

export default useAuth;
