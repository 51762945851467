import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import MessageTemplatesNewPage from './pages/MessageTemplatesNewPage';
import { lazy, Suspense } from 'react';
import { Spinner } from '@7shifts/sous-chef';

const MessageTemplatesPage = lazy(() => import('./pages/MessageTemplatesPage'));

export const ROUTES = [
    {
        path: '/message-templates',
        element: (
            <Suspense fallback={<Spinner block />}>
                <MessageTemplatesPage />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />
    },
    {
        path: '/message-templates/:id/new',
        element: (
            <Suspense fallback={<Spinner block />}>
                <MessageTemplatesNewPage />
            </Suspense>
        ),
        errorElement: <ErrorBoundary />
    }
];
