import classNames from 'classnames';
import { useAuthContext } from '../../contexts/AuthContext';
import './UserStatusBullet.scss';

const UserStatusBullet = () => {
    const { user } = useAuthContext();

    return (
        <div
            className={classNames('user-status-bullet', {
                'user-status-bullet--away': !user.statusAvailable
            })}
        />
    );
};

export default UserStatusBullet;
