import { __ } from 'i18n/translate';
import api from '../services/api';
import { MetaPaging, MetaTemplate } from 'types';

export type GetTemplatesResponse = {
    data: MetaTemplate[];
    paging: MetaPaging;
};

export const getMetaTemplates = (
    cursor?: string,
    whatsappId?: number
): Promise<GetTemplatesResponse> => {
    return api
        .get('/meta/getTemplates', { params: { cursor, whatsappId } })
        .then(({ data }) => data);
};

export const sendTemplate = (
    templateName: string,
    ticketUUID: string
): Promise<void> => {
    return api.post(`/messages/sendTemplate/${ticketUUID}`, {
        templateName,
        components: []
    });
};

export const uploadMedia = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('media', file);
    const result = await api.post('/meta/templateMedia', formData, {
        validateStatus: () => true
    });
    if (result.status === 200) {
        return result.data.result;
    }
    throw __('messageTemplateForm.uploadError');
};
