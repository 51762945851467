import { ReactNode, createContext, useContext } from 'react';

import useWhatsApps from './useWhatsApp';
import { WhatsApp } from 'types';

const WhatsAppsContext = createContext({});

type ContextType = {
    whatsApps: WhatsApp[];
    loading: boolean;
};

type Props = {
    children: ReactNode;
};
const WhatsAppProvider = ({ children }: Props) => {
    const { loading, whatsApps } = useWhatsApps();

    return (
        <WhatsAppsContext.Provider value={{ whatsApps, loading }}>
            {children}
        </WhatsAppsContext.Provider>
    );
};

const useWhatsContext = (): ContextType => {
    const context = useContext(WhatsAppsContext);

    if (!context) {
        throw new Error('WhatsApp context not in place!');
    }

    return context as ContextType;
};

export { WhatsAppsContext, WhatsAppProvider, useWhatsContext };
